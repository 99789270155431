/**
 * Individual quote page.
 */

import React from "react"
import {graphql, Link} from "gatsby"
import Seo from "../components/seo";
import Layout from "../components/layout";
import Sidebar from "../components/sidebar";

const QuoteTemplate = ({data, location}) => {

    const siteTitle = data.site.siteMetadata?.title || `Title`;

    const quote = data.markdownRemark;
    return (
        <Layout location={location} title={siteTitle}>
            <Seo
                title={quote.frontmatter.title}
                description={quote.frontmatter.description || quote.excerpt}
            />

            <Sidebar/>

            <div className="main-content">
                <article className="quote content">
                    <section
                        className="quote-body"
                        dangerouslySetInnerHTML={{__html: quote.html}}
                        itemProp="articleBody"
                    />

                    <div className="quote-author">{quote.frontmatter.author}</div>
                    <div className="quote-source">{quote.frontmatter.source}</div>

                    <div className="quote-nav">
                        <Link to='/quotes' className='quotes-more-link'>More quotations</Link>
                        <Link className="quote-nav-link" to="/quotes/random">Random quote</Link>
                    </div>
                </article>
            </div>
        </Layout>
    )
};

export default QuoteTemplate;

export const pageQuery = graphql`
  query Quote(
    $id: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        author
        source
        source_link
      }
    }
  }
`;
